import React from 'react';
import Link from 'next/link';
import { PlusIcon } from '@heroicons/react/24/outline';

interface CreateCardProps {
  href?: string;
  singularSectionName?: string;
  pluralSectionName?: string;
  female?: boolean;
}

function CreateCard({
  href,
  singularSectionName,
  pluralSectionName,
  female = false,
}: CreateCardProps) {
  return (
    <>
      <Link href={href}>
        <div className="inline-flex my-auto bg-secondary-500 dark:bg-secondary-500 dark:border dark:border-primary-500 dark:hover:text-primary-500 hover:bg-secondary-500 text-slate-50 rounded px-2 py-0.5 mb-2">
          <PlusIcon className="h-3 w-3 my-auto mr-2" />
          <span className="text-xs my-auto">{singularSectionName}</span>
        </div>
      </Link>
      {female ? (
        <p>
          {`En este momento no se encuentran ${pluralSectionName} registradas.`}{' '}
        </p>
      ) : (
        <p>
          {`En este momento no se encuentran ${pluralSectionName} registrados.`}{' '}
        </p>
      )}
    </>
  );
}

export default CreateCard;
