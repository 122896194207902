import React from 'react';
import { Navbar, Footer, Sidebar } from '../common';
import Breadcrumb from '../../lib/breadcrumb';
import { IRoute } from '../../routes';

interface LayoutProps {
  children: React.ReactChild | Array<React.ReactChild>;
  title: string;
  routesHistory?: IRoute;
}

function Layout({ children, title = '', routesHistory }: LayoutProps) {
  const [isShown, setIsShown] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  return (
    <>
      <Sidebar isShown={isShown} setIsShown={setIsShown} setOpen={setOpen} />
      <section className="relative md:pl-16 w-full px-4 pt-5">
        {/* <Navbar /> */}
        <div className="relative z-0">
          <div className="px-4 pt-8 mx-auto w-full flex flex-col gap-4">
            {/* <Breadcrumb actualRoute={routesHistory} /> */}
            <h2 className="text-xl text-black font-semibold">{title}</h2>
          </div>
        </div>
        <div className="px-4 m-auto lg:py-3 mx-auto w-full z-10 min-h-screen mt-4 lg:mt-0">
          {children}
        </div>
        <div className="px-4 lg:py-6 mx-auto w-full z-10">
          <Footer />
        </div>
      </section>
    </>
  );
}

export default Layout;
