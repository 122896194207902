import React from 'react';

function SignOutIcon({ className, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M0 17.778L6.667 20V0L0 2.222v15.556zm5.556-8.445c.377 0 .666.29.666.667a.655.655 0 01-.666.667.655.655 0 01-.667-.667c0-.378.311-.667.667-.667zM16.667 7.311l-.378.378a.43.43 0 000 .622l.978.978h-7.045V2.667c0-.245-.2-.445-.444-.445H8.444c-.244 0-.444.2-.444.445v.222c0 .244.2.444.444.444h.223c.244 0 .444.2.444.445v12.444c0 .245-.2.445-.444.445h-.223c-.244 0-.444.2-.444.444v.222c0 .245.2.445.444.445h1.334c.244 0 .444-.2.444-.445v-6.622h7.067l-.978.978a.43.43 0 000 .622l.378.378a.43.43 0 00.622 0l2.378-2.378a.43.43 0 000-.622L17.31 7.31a.456.456 0 00-.644 0z"
      />
    </svg>
  );
}

export default SignOutIcon;
